<main id="view-oow">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="inner-main-content oow-content">
        <!-- xl container -->
        <div class="xl-container">

            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                <csi-facebook-pixel *ngIf="pixelId" [pixelId]="pixelId" [pageEventName]="pageEventName"></csi-facebook-pixel>

                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->
            </div>
            
            <csi-loading [isLoading]="isLoading"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div *ngIf="!isLoading" class="page-body-content">
                <section class="applicant-body">

                    <!-- RESKIN: creating new container elements for column layout -->
                    <!-- RESKIN: col 1 -->
                    <div class="applicant-body-main">
                        <!-- header -->
                        <div class="applicant-header">
                            <div class="applicant-title">
                                <h1 class="subhead">Verify Information</h1>
                            </div>
                        </div>
                        <!-- /header -->
        
                        <!-- content -->
                        <out-of-wallet-question *ngFor="let question of questions; let i = index" [name]="i" [question]="question" [autoFill]="autoFill"
                            (answerUpdated)="handleUpdatedAnswer(i, $event)"></out-of-wallet-question>
                        <!-- /content -->
                    </div>
                    <!-- /RESKIN: col 1 -->

                    <!-- RESKIN: col 2 -->
                    <div class="applicant-body-aside">
                        <div class="applicant-controls" *ngIf="allowMultipleApplicants">
                            <!-- <div class="applicant-control-items"> -->
                                <!-- add applicant -->
                                <div *ngIf="applicantsState.applicants.length < 2" class="applicant-control applicant-control-add">
                                    <div class="applicant-control-info">
                                        <h3>Add another person?</h3>
                                        <p>If you want to add another person, click the box below. After verifying your personal information, you will be able to enter their information.</p>
                                    </div>
                                    <div class="applicant-control-action">
                                        <button (click)="addApplicant()" class="applicant-control-button">
                                            <span class="icon icon-user-plus"></span> Add Another Person
                                        </button>
                                    </div>
                                </div>
                                <!-- /add applicant -->

                                <!-- remove applicant -->
                                <ng-container *ngIf="applicantsState.applicants.length == 2 && applicant == 0">
                                    <div class="applicant-control applicant-control-remove">
                                        <div class="applicant-control-info">
                                            <h3>Additional Person Added</h3>
                                            <p>If you want to remove this additional person, click the box below.</p>
                                        </div>
                                        <div class="applicant-control-action">
                                            <button (click)="removeApplicant(1)" class="applicant-control-button remove-item">
                                                <span class="icon icon-user-times"></span> Remove Additional Person
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- /remove applicant -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <!-- /RESKIN: col 2 -->

                    </section>
            </div>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="!canSubmitAnswers" (click)="scoreAnswers()" data-continue-page="oow" class="next-button">Continue</button>
    </div>
    <!-- /"continue" button -->

</main>