<main id="view-personalInfo">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="inner-main-content businessInfo-content">
        <!-- xl container -->
        <div class="xl-container">

            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress (signOut)="handleSignOutClick()"></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->
            </div>

            <csi-loading [isLoading]="isLoadingApplicants"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div [hidden]="isLoadingApplicants" class="page-body-content">

                <!-- business info -->
                <section class="applicant-body">

                    <!-- RESKIN: creating new container elements for column layout -->
                    <!-- RESKIN: col 1 -->
                    <div class="applicant-body-main-company">
                        <!-- header -->
                        <div class="applicant-header">
                            <div class="applicant-title">
                                <h1 class="subhead">Business Information</h1>
                            </div>
                        </div>

                        <!-- admin -->
                        <div class="admin-container" *ngIf="showAdminAutoFill">
                            <ul class="admin-items">
                                <li>
                                    <a href="#" (click)="onAutofillClick()" class="admin-button">
                                        <span class="icon icon-cog"></span> Auto Fill Content
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- /admin -->
                        <!-- /header -->

                        <!-- content -->
                        <!-- signer(s) information -->
                        <div *ngFor="let applicant of applicants; let ordinal = index" class="applicant-information">
                            <csi-business-applicant
                                *ngIf="ordinal === 0"
                                [(business)]="applicants[ordinal]"
                                [editable]="!applicant.completeOnServer"
                                [businessTypes]="institution.businessTypes"
                                (valid)="handleApplicantValidationChange(ordinal, $event)"
                                class="business-info-container">
                            </csi-business-applicant>
                        </div>
                    </div>
                </section>
                <!-- business info -->

                <!-- signers info -->
                <section class="applicant-body">
                    <div class="applicant-body-main-signer">
                        <div *ngFor="let applicant of applicants; let ordinal = index" class="applicant-information">
                            <div class="applicant-signer-header" *ngIf="ordinal === 0">
                                <div class="applicant-signer-title">
                                    <h1 class="subhead">Business Signers</h1>
                                    <p>Please enter the information for at least one signer. You can provide up to four signers.</p>
                                </div>
                            </div>
        
                            <csi-signer-applicant
                                *ngIf="ordinal !== 0"
                                [(signer)]="applicants[ordinal]"
                                [editable]="!applicant.id || applicant.id.length === 0"
                                [deleteable]="applicants.length > 2"
                                (delete)="deleteApplicant(applicant)"
                                [ordinal]="ordinal"
                                [identificationTypes]="institution.identificationTypes"
                                (valid)="handleApplicantValidationChange(ordinal, $event)"
                                class="business-signer-container">
                            </csi-signer-applicant>
                        </div>
                        <!-- /signer(s) information -->
                        <!-- /content -->
                    </div>
                    <!-- /RESKIN: col 1 -->

                    <!-- RESKIN: col 2 -->
                    <div class="applicant-body-aside">

                        <!-- applicant controls -->
                        <div class="applicant-controls">

                            <!-- add signer -->
                            <div *ngIf="applicants.length < 5" (click)="addSigner()" class="applicant-control applicant-add-container">
                                <div class="applicant-control-info">
                                    <h3>Need additional signers?</h3>
                                    <p>You can add up to four total signers to be included with this business.</p>
                                </div>
                                <div class="applicant-control-action">
                                    <button class="applicant-control-button">
                                        Add Another Signer <span class="icon icon-chevron-right"></span>
                                    </button>
                                </div>
                            </div>
                            <!-- add signer -->
                        </div>
                        <!-- /applicant controls -->

                    </div>
                    <!-- /RESKIN: col 2 -->
    
                </section>
            </div>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress (signOut)="handleSignOutClick()"></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
      <button [disabled]="!isApplicationValid || isLoadingApplicants" (click)="submitApplicants(true)" data-continue-page="personalInformation" class="next-button">Continue</button>
    </div>
    {{isApplicationValid}}
    {{isLoadingApplicants}}
    <!-- /"continue" button -->

</main>