<main id="view-products">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="products-content">
        <div class="inner-main-content">
        <!-- xl container -->
        <div class="xl-container">
            <!-- don't show header unless there are products -->
            <div *ngIf="!isLoading && products !== null && products.length > 0" [hidden]="hideSelectorForInstantAddSkip" class="page-header-content dispaly-block">
                <page-header [pageName]="pageName" class="page-header-logo-title" [style.marginTop]="isSSO ? '2em': ''"></page-header>
                <div class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                <csi-facebook-pixel *ngIf="pixelId" [pixelId]="pixelId" [init]="true" [pageEventName]="pageEventName"></csi-facebook-pixel>

                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->

                <div *ngIf="!isLoading" [hidden]="hideSelectorForInstantAddSkip || hidePromoCode" class="page-header-promo-cart">
                    <promo-code-entry [instantAddCode]="instantAddCode" class="product-component products-promo"></promo-code-entry>
                    <products-cart *ngIf="!useNewUiForBank" #productsCart (productRemove)="onProductRemove($event)" (bundleRemove)="onBundleRemove($event)" [selectedProducts]="selectedProducts" [selectedBundles]="selectedBundles" class="product-component products-cart"></products-cart>
                </div>
            </div>

            <csi-loading [isLoading]="isLoading || hideSelectorForInstantAddSkip"></csi-loading>

            <!-- esign -->
            <csi-esign *ngIf="isEsignRequired()" [useDarkStyles]="true" [verifyPDFOpened]="requireDemonstratedEsignConsent" [disclosureURL]="getEsignLink()" [disclosureDescription]="getEsignDescription()" (esignValidChanged)="handleEsignValidChanged($event)"></csi-esign>
            <!-- /esign -->

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <products-selector *ngIf="!isLoading && products !== null && products.length > 0; else noProducts" [instantAddCode]="instantAddCode"
                [products]="products" class="page-body-content products-container" (addProduct)="onProductAdd($event)" (removeProduct)="onProductRemove($event)" (addBundle)="onBundleAdd($event)" (removeBundle)="onBundleRemove($event)"
                [hidden]="hideSelectorForInstantAddSkip"></products-selector>

            <ng-template #noProducts>
                <div *ngIf="!isLoading" class="page-body-content" [hidden]="hideSelectorForInstantAddSkip">
                    <div style="text-align: center;"><h2>We are sorry there are no products available for the information you entered.</h2></div>
                    <div style="text-align: center;"><h2>Please contact us for more information.</h2></div>
                    <div><br></div>
                </div>
            </ng-template>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>
    
        </div>
        <!-- /xl container -->

        </div>
    
    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="!hasSelectedProducts() || isLoading || esignIsInvalid" (click)="confirmProductSelection()" data-continue-page="products" class="next-button">Continue</button>
    </div>
    <!-- /"continue" button -->

</main>