<main id="view-disclosures">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="inner-main-content disclosures-content">

        <!-- xl container -->
        <div class="xl-container">

            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                <csi-facebook-pixel *ngIf="pixelId" [pixelId]="pixelId" [pageEventName]="pageEventName"></csi-facebook-pixel>

                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->
            </div>
            
            <csi-loading [isLoading]="isLoading"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div *ngIf="!isLoading" class="page-body-content">
                <section class="disclosures-body">
                    <!-- disclosure(s) listing -->
                    <ul class="disclosures-list">
                        <li class="disclosures-item" *ngIf="!disclosuresExist()">
                            There are no disclosures. Click continue to proceed with your session.
                        </li>
                        <!-- item -->
                        <ng-container *ngFor="let product of selectedProducts; let productIndex = index;">
                            <li class="disclosures-item" *ngIf="product.disclosures != null && product.disclosures.length">
                                <form class="disclosures-form">
                                    <div class="disclosure-productname">
                                        <!--<h4 [innerHTML]="product.productName"></h4>-->
                                        <!--<h6 [innerHTML]="product.applicationCode">ProductCategory</h6>-->

                                        <h4 class="disclosure-productname-name" *ngIf="!product.productAlias; else alias">
                                            {{product.productName}}
                                        </h4>
                                        <ng-template #alias>
                                            <h4 class="disclosure-productname-name">
                                                {{product.productName}} ({{product.productAlias}})
                                            </h4>
                                        </ng-template>
                                        <h6 class="disclosure-productname-type">{{product.applicationCode}}</h6>
                                    </div>
                                    <div class="disclosure-docs">
                                        <ul class="disclosure-docs-list">
                                            <li *ngFor="let disclosure of product.disclosures | orderBy: 'displayOrder'; let disclosureIndex = index;" class="disclosure-docs-item">
                                                <!-- options -->
                                                <div class="disclosure-docs-options">
                                                    <!--<span [innerHTML]="disclosure.disclosureName"></span>-->
                                                    <div class="disclosure-name">{{disclosure.disclosureName}}</div>
    
                                                    <ul class="disclosure-options">
                                                        <li class="disclosure-option-view" *ngIf="disclosure.documentAvailable">
                                                            <a *ngIf="disclosure.documentType.toLocaleLowerCase() === 'htm'" href="javascript: void(0);" (click)="modalDisclosure(disclosure)" target="_blank" class="disclosure-option disclosure-option-html">View Disclosure</a>
                                                            <a *ngIf="disclosure.documentType.toLocaleLowerCase() !== 'htm'" href="{{getDisclosureLink(disclosure)}}" target="_blank" class="disclosure-option disclosure-option-other" (click)="handlelDisclosurePDFClick($event)">View Disclosure</a>
                                                        </li>
                                                        <li class="disclosure-option-accept" *ngIf="!disclosure.isAccepted">
                                                            <a href="javascript: void(0);" (click)="acceptDisclosure(disclosure)" class="disclosure-option">Accept</a>
                                                        </li>
                                                        <li class="disclosure-option-accept accepted" *ngIf="disclosure.isAccepted">
                                                            <span class="disclosure-option"><span class="icon icon-check"></span>Accepted</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <!-- /options -->
    
                                                <!-- text -->
                                                <div class="disclosure-displaytext" *ngIf="disclosure.displayText != null && disclosure.displayText.length" [innerHTML]="disclosure.displayText"></div>
                                                <div class="disclosure-acceptance" *ngIf="disclosure.acceptanceRequired">
                                                    <div class="form-items form-radio">
                                                        <input type="radio" required value="yes" [(ngModel)]="disclosure.disclosureResponse" id="{{disclosure.disclosureId}}_y_{{productIndex}}_{{disclosureIndex}}"
                                                            name="{{disclosure.disclosureId}}_acceptance_{{productIndex}}_{{disclosureIndex}}">
                                                        <label for="{{disclosure.disclosureId}}_y_{{productIndex}}_{{disclosureIndex}}">Yes</label>
                                                    </div>
                                                    <div class="form-items form-radio">
                                                        <input type="radio" required value="no" [(ngModel)]="disclosure.disclosureResponse" id="{{disclosure.disclosureId}}_n_{{productIndex}}_{{disclosureIndex}}"
                                                            name="{{disclosure.disclosureId}}_acceptance_{{productIndex}}_{{disclosureIndex}}">
                                                        <label for="{{disclosure.disclosureId}}_n_{{productIndex}}_{{disclosureIndex}}">No</label>
                                                    </div>
                                                </div>
                                                <!-- /text -->
                                                
                                                <!-- mappedQuestion -->
                                                <ol class="disclosure-questions" *ngFor="let question of disclosure.questions | orderBy: 'displayOrder'; let questionIndex = index;">
                                                    <li class="disclosure-question">
                                                        <div class="disclosure-question-text">{{ question.question }}</div>
                                                        <div class="disclosure-question-answers">
                                                            <div class="form-items form-radio disclosure-question-answer">
                                                                <input type="radio" required value="yes" [(ngModel)]="question.answer" id="{{disclosure.disclosureId}}_y_{{productIndex}}_{{disclosureIndex}}_{{questionIndex}}"
                                                                    name="{{disclosure.disclosureId}}_acceptance_{{productIndex}}_{{disclosureIndex}}_{{questionIndex}}">
                                                                <label for="{{disclosure.disclosureId}}_y_{{productIndex}}_{{disclosureIndex}}_{{questionIndex}}">Yes</label>
                                                            </div>
                                                            <div class="form-items form-radio disclosure-question-answer">
                                                                <input type="radio" required value="no" [(ngModel)]="question.answer" id="{{disclosure.disclosureId}}_n_{{productIndex}}_{{disclosureIndex}}_{{questionIndex}}"
                                                                    name="{{disclosure.disclosureId}}_acceptance_{{productIndex}}_{{disclosureIndex}}_{{questionIndex}}">
                                                                <label for="{{disclosure.disclosureId}}_n_{{productIndex}}_{{disclosureIndex}}_{{questionIndex}}">No</label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ol>
                                                <!-- /mappedQuestion -->
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </li>
                        </ng-container>
                        <!-- /item -->
                    </ul>

                    <div *ngIf="isBuisness" class="file-button-row">
                        <input type="file" #businessNameFile (change)="onFileChange($event, 'businessNameFile')" accept="application/pdf" />
                        <div class='file-button-wrapper'>
                            <div class="file-button-label">
                                Proof Of Business Name
                            </div>
                            <div class="file-button-container" (click)="businessNameFile.click()">
                                <div class="file-button-content">
                                    <div *ngIf="getFileIndex('businessNameFile') > -1">
                                        <div class="file-button-delete-file icon icon-x" (click)="onFileDelete($event, 'businessNameFile', businessNameFile)"></div>
                                        <div class="file-button-file-name">{{files[getFileIndex('businessNameFile')].file.name}}</div>                                        
                                    </div>
                                    <div *ngIf="getFileIndex('businessNameFile') <= -1">Upload PDF File</div>                                    
                                </div>                                
                            </div>
                        </div>
                        <input type="file" #proofOfAddressFile (change)="onFileChange($event, 'proofOfAddressFile')" accept="application/pdf" />
                        <div class='file-button-wrapper'>
                            <div class="file-button-label">
                                Proof Of Address
                            </div>
                            <div class="file-button-container" (click)="proofOfAddressFile.click();">
                                <div class="file-button-content">
                                    <div *ngIf="getFileIndex('proofOfAddressFile') > -1">
                                        <div class="file-button-delete-file icon icon-x" (click)="onFileDelete($event, 'proofOfAddressFile', proofOfAddressFile)"></div>
                                        <div class="file-button-file-name">{{files[getFileIndex('proofOfAddressFile')].file.name}}</div>
                                    </div>
                                    <div *ngIf="getFileIndex('proofOfAddressFile') <= -1">Upload PDF File</div>                                    
                                </div>
                            </div>
                        </div>
                        <input type="file" #proofOfTinFile (change)="onFileChange($event, 'proofOfTinFile')" accept="application/pdf" />
                        <div *ngIf="!isSoleProprietor" class='file-button-wrapper'>
                            <div class="file-button-label">
                                Proof Of TIN
                            </div>
                            <div class="file-button-container" (click)="proofOfTinFile.click();">
                                <div class="file-button-content">
                                    <div *ngIf="getFileIndex('proofOfTinFile') > -1">
                                        <div class="file-button-delete-file icon icon-x" (click)="onFileDelete($event, 'proofOfTinFile', proofOfTinFile)"></div>
                                        <div class="file-button-file-name">{{files[getFileIndex('proofOfTinFile')].file.name}}</div>
                                    </div>
                                    <div *ngIf="getFileIndex('proofOfTinFile') <= -1">Upload PDF File</div>                                    
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFileTooBig">Not All Files Uploaded Due To Size Restriction</div>
                    </div>
                </section>
            </div>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="!canContinue()" (click)="selectFundingSources()" data-continue-page="disclosures" class="next-button">Continue</button>
    </div>
    <!-- /"continue" button -->

</main>
