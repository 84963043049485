<main id="view-funding">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="inner-main-content funding-content">

        <!-- xl container -->
        <div class="xl-container">
            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->
            </div>

            <csi-loading [isLoading]="isLoading"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div *ngIf="!isLoading" class="page-body-content">
                <section class="funding-body">

                    <ul class="funding-products">
                        <li *ngFor="let product of selectedProducts; let productIndex = index;" class="funding-product" [ngClass]="{'funding-product-hide' : product.applicationCode == 'CRD'}">
    
                            <form class="form-funding" *ngIf="product.availableFundingMethods.length && product.selectedFundingMethods.length">
                                <fieldset class="form-funding-fieldset">
                                    <legend class="form-funding-name" [innerHTML]="getFundingHeading(product)"></legend>
    
                                    <section>
                                        <div class="funding-subhead">
                                            <h5 class="subhead">Funding Source</h5>
                                            <p>How do you want to fund your new account?</p>
                                        </div>
    
                                        <!-- funding options -->
                                        <div class="form-input-container-flex">
                                            <div class="funding-option" *ngFor="let fundingMethod of product.availableFundingMethods">
                                                <div class="form-items form-radio form-radio-icon-button">
                                                    <input type="radio" (change)="resetACHAuthorization()" [value]="fundingMethod.type" id="{{product.productId}}_fundingId_{{fundingMethod.id}}_{{productIndex}}"
                                                        name="{{product.productId}}_fundingId_{{productIndex}}_{{productIndex}}" [(ngModel)]="product.selectedFundingMethods[0].type">
                                                    <label for="{{product.productId}}_fundingId_{{fundingMethod.id}}_{{productIndex}}"><span class="icon" [ngClass]="{ 'icon-bank-transfer': fundingMethod.description == 'ACH', 'icon-checkbook': fundingMethod.description == 'Personal Check' }"></span> {{translateFundingDescription(fundingMethod.description)}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- funding options -->
    
                                        <!-- selection options -->
                                        <div class="funding-source" *ngFor="let fundingMethod of product.availableFundingMethods">
                                            <fieldset class="funding-source-fieldset" *ngIf="fundingMethod.type==product.selectedFundingMethods[0].type">
                                                <!--<legend class="funding-source-name">{{fundingMethod.description}}</legend>-->
                                                <div *ngIf="fundingMethod.description !== 'Employer HSA'" class="funding-source-info">
                                                    <div class="form-items form-source-amount">
                                                        <label for="{{product.productId}}_Amount_{{productIndex}}" class="required">Amount</label>
                                                        <input type="number" [min]="product.minimumDeposit" [max]="getProductMaximumFunding(product)" id="{{product.productId}}_Amount_{{productIndex}}"
                                                            name="{{product.productId}}_Amount_{{productIndex}}"
                                                            [(ngModel)]="product.openAmount" (blur)="formatOpenAmount(product)"
                                                            class="form-input form-size" #validAmount="ngModel"
                                                            (input)="resetACHAuthorization()" [attr.data-funding-method]="fundingMethod.description">
    
                                                        <!-- valid alert -->
                                                        <div *ngIf="!fundingAmountIsValid(product) && validAmount.touched" class="form-source-valid">
                                                            <div class="alert alert-danger">
                                                                Please enter a valid amount.
                                                            </div>
                                                        </div>
                                                        <!-- /valid alert -->
                                                    </div>
    
                                                    <div class="form-source-info" *ngIf="fundingMethod.type == 'Personal Check'">
                                                        <p><strong>Note:</strong> You will receive instructions in a confirmation email
                                                            on where to send your personal check.</p>
                                                    </div>
                                                    <div class="form-source-info" *ngIf="fundingMethod.type == 'ACH'">
                                                        <p><i class="icon icon-chevron-down anim-bounce" aria-hidden="true"></i><strong>Note:</strong>                                                    Please fill out the additional funding information below.
                                                        </p>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- /selection options -->
                                    </section>
    
                                </fieldset>
                            </form>
                        </li>
                    </ul>
    
    
                    <!-- ACH funding info -->
                    <div *ngIf="isAnyProductACH()" class="funding-ach">
                        <div class="funding-subhead">
                            <h5 class="subhead">Account Transfer</h5>
                            <p>Please select which type of account you would like to use and enter your bank's routing number and your account number.</p>
                            <p><small>Need help locating your routing number and account number on a check? <a (click)="isSampleVisible = !isSampleVisible;">View a sample.</a></small></p>
                        </div>
    
                        <!-- sample -->
                        <div class="funding-ach-sample" [class.sample-on]="isSampleVisible">
                            <!-- <p><small><strong>Sample from bottom-left of a physical check</strong></small></p> -->
                            <a (click)="isSampleVisible = !isSampleVisible;" class="funding-ach-sample-close">&times;</a>
                            <div class="funding-ach-sample-container">
                                <div class="funding-ach-sample-inner">
                                    <!-- <img src="./assets/images/sample-check-routingacct@2x.png" class="funding-ach-sample-img"> -->
                                    <!-- <img src="./assets/images/sample-check-v2@2x.png" class="funding-ach-sample-img"> -->
                                    <img src="./assets/images/sample-check-v3@2x.png" class="funding-ach-sample-img">
                                </div>
                            </div>
                        </div>
                        <!-- /sample -->
    
    
                        <div class="funding-source-ach">
                            <!-- ach head -->
                            <div class="ach-head">
                                <div class="ach-sample-from">
                                    <p><strong>John Doe Sample Check</strong><br> 123 Main Street<br> Anytown, USA 55555</p>
                                </div>
                                <div class="ach-sample-checknumber">
                                    <p><strong>0001</strong></p>
                                </div>
                            </div>
                            <!-- /ach head -->
                            <!-- ach body -->
                            <div class="ach-body">
                                <!-- amount -->
                                <div class="form-input-container">
                                    <div class="form-items form-ach-amount">
                                        <label for="ACHAmount">Total Amount</label>
                                        <input type="number" readonly id="ACHAmount" name="ACHAmount" value="{{getACHTotal()}}" class="form-input form-size" placeholder="Amount" (input)="resetACHAuthorization()">
                                    </div>
                                </div>
                                <!-- amount -->
    
                                <!-- use internal account -->
                                <div class="form-input-container-usebank">
                                    <!-- checkbox -->
                                    <div class="form-items form-checkbox form-ach-usecheckbox">
                                        <input type="checkbox" [(ngModel)]="onUs" id="onUs" name="onUs" autocomplete="off" />
                                        <label for="onUs">
                                            <p>Use my <strong>{{institutionName}}</strong> account</p>
                                        </label>
                                    </div>
                                    <!-- /checkbox -->
    
                                    <!-- FUTURE: accounts dropdown -->
                                    <!-- <div class="form-items form-ach-accountlist">
                                        <label for="ACHAccountList" class="required">Select an Account to Use</label>
                                        <select class="form-select " id="ACHAccountList " name="ACHAccountList">
                                            <option value="TBD">TBD</option>
                                        </select>
                                    </div> -->
                                    <!-- /FUTURE: accounts dropdown -->
                                </div>
                                <!-- use internal account -->
    
                                <!-- bank name -->
                                <div class="form-input-container">
                                    <div class="form-items form-ach-bankname">
                                        <input type="text" class="form-input form-size-sm" readonly placeholder="Bank Name" value="{{ ACHFundingSource.bankName }}"
                                            *ngIf="ACHFundingSource.bankName !== '' && !onUs">
                                    </div>
                                </div>
                                <!-- /bank name -->
    
                                <div class="form-input-container">
                                    <!-- account type -->
                                    <div class="form-items form-ach-accounttype">
                                        <label for="ACHAccountType" class="required">Account Type</label>
                                        <select class=" form-select " id="ACHAccountType " name="ACHAccountType" [(ngModel)]="ACHFundingSource.accountType" (input)="resetACHAuthorization()">
                                            <option value="DDA">Checking</option>
                                            <option value="SAV">Savings</option>
                                        </select>
                                    </div>
                                    <!-- /account type -->
                                    <!-- routing number -->
                                    <div class="form-items form-ach-routingnumber">
                                        <label for="ACHRoutingNumber" class="required">Routing Number</label>
                                        <input type="text" id="ACHRoutingNumber" name="ACHRoutingNumber" [(ngModel)]="ACHFundingSource.routingNumber" (input)="processRoutingChange($event);"
                                            class="form-input" [class.sample-on]="isSampleVisible" [disabled]="onUs" maxlength="9">
                                    </div>
                                    <!-- /routing number -->
                                    <!-- account number -->
                                    <div class="form-items form-ach-accountnumber">
                                        <label for="ACHAccountNumber" class="required">Account Number</label>
                                        <input type="text" id="ACHAccountNumber" name="ACHAccountNumber" [(ngModel)]="ACHFundingSource.accountNumber" class="form-input"
                                            [class.sample-on]="isSampleVisible" (input)="handleACHAccountNumberChange($event)" maxlength="17">
                                    </div>
                                    <!-- /account number -->
                                </div>
    
                            </div>
                            <!-- /ach body -->
    
                            <div class="funding-authorize form-items form-checkbox form-checkbox-multiline">
                                <input type="checkbox" [(ngModel)]="achAccepted" id="ach" name="ach" autocomplete="off" />
                                <label for="ach">
                                    <div class="form-checkbox-info">
                                        <p>I authorize the onetime bank payment recorded on this page to be scheduled and initiated.</p>
                                        <!-- <p class="funding-print"><a href="/assets/ach.pdf" target="_blank">View and Print the Agreement</a></p> -->
                                        <p *ngIf="canContinue()">Click to view and print the <a href="{{getACHPDFURL()}}" target="_blank">ACH authorization disclosure</a>.</p>
                                    </div>
                                </label>
                            </div>
    
                        </div>
                    </div>
                    <!-- /ACH funding info -->
    
                </section>
            </div>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="!canContinue()" (click)="next()" data-continue-page="funding" class="next-button">Continue</button>
    </div>
    <!-- /"continue button -->

</main>