<main class="view-completion">
    <!-- col 1 -->
    <section class="inner-main-content">
        <!-- xl container -->
        <div class="xl-container">
            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                <csi-facebook-pixel *ngIf="pixelId" [pixelId]="pixelId" [pageEventName]="pageEventName"></csi-facebook-pixel>
            </div>

            <csi-loading [isLoading]="isLoading"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div *ngIf="!isLoading" class="page-body-content">
                <section class="completion-content">
                    <!-- confirmation info -->
                    <div class="confirmation-info">
                        <div class="confirmation-info-details">
                            <p class="confirmation-text-info">A confirmation email has been sent to you. Your <strong>Confirmation Code</strong> is:</p>
                            <p class="confirmation-info-code">{{ confirmationCode }}</p>
                            <p class="confirmation-text-small"><small>Please store this code in a secure location as you will need it to communicate with us.</small></p>
                            <ul class="confirmation-info-links">
                                <li><a href="#" (click)="openDiscloresWindow()" target="_self" class="confirmation-info-link confirmation-info-link-disclosures">View Disclosures<span class="icon icon-chevron-right"></span></a></li>
                                <li><a href="#" (click)="resendEmail()" target="_self" class="confirmation-info-link confirmation-info-link-email">Resend Confirmation Email<span class="icon icon-chevron-right"></span></a></li>
                            </ul>
                        </div>
                        <div *ngIf="showDigitalLink" class="confirmation-info-db">
                            <h3 class="confirmation-info-db-subhead">Access Your New Account(s)!</h3>
                            <p>Log in to your Digital Banking Account to manage your account(s) and view available features.</p>
                            <a href="#" (click)="openDigitalBanking()" target="_self" class="confirmation-info-link-db">Continue to Digital Banking<span class="icon icon-chevron-right"></span></a>
                        </div>
                    </div>
                    <!-- /confirmation info -->

                    <!-- confirmation products -->
                    <div class="confirmation-products" *ngIf="accounts.length > 0">
                        <h5 class="subhead"><span class="icon icon-cash-usd-outline"></span>Your Selected Product(s):</h5>
                        <ul class="confirmation-products-list">
                            <li *ngFor="let product of accounts" class="confirmation-products-item">
                                <div class="confirmation-product-head">
                                <h2 class="confirmation-product-title">{{product.productName}}</h2>
                                <h3 class="confirmation-product-alias" *ngIf="product.productAlias != null"><small>Nickname</small>{{product.productAlias}}</h3>
                                </div>
                                <ng-container *ngIf="product.accountNumber != null && product.accountNumber.length">
                                    <div class="confirmation-product-foot">
                                        <small>Account Number</small>
                                        <h1 class="confirmation-product-account-number" [ngClass]="{ 'confirmation-product-account-number-long': product.accountNumber.length > 16 }">{{product.accountNumber}}</h1>
                                    </div>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                    <!-- /confirmation products -->

                    <!-- confirmation cards -->
                    <div class="confirmation-products" *ngIf="selectedProducts && cards.length > 0">
                        <h5 class="subhead"><span class="icon icon-credit-card-outline"></span>Your Card(s):</h5>
                        <ul class="confirmation-products-list">
                            <li *ngFor="let product of cards" class="confirmation-products-item">
                                <div class="confirmation-product-head">
                                    <h2 class="confirmation-product-title">{{product.productName}}</h2>
                                    <h3 class="confirmation-product-alias" *ngIf="product.productAlias != null"><small>Nickname</small>{{product.productAlias}}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- /confirmation cards -->
                </section>
            </div>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>
    <!-- /col 1 -->
</main>
