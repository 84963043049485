<main id="view-features">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="features-content">
        <div class="page-promo-container" *ngIf="hasPromoCode">
            A promo code has been entered for this session.
        </div>
        <div class="inner-main-content">
            <!-- xl container -->
            <div class="xl-container">

                <div class="page-header-content">
                    <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                    <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                    <!-- RESKIN: NAV -->
                    <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                        <wizard-progress></wizard-progress>
                    </div>
                    <!-- /RESKIN: NAV -->

                </div>

                <csi-loading [isLoading]="isLoading"></csi-loading>

                <!-- RESKIN: PAGETEXT -->
                <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
                <!-- /RESKIN: PAGETEXT -->

                <div *ngIf="!isLoading" class="page-body-content">
                    <ul class="product-features-products">
                        <li *ngFor="let product of selectedProducts">
                            <div class="product-features">
                                <form class="form-product-features" novalidate>
                                    <fieldset class="form-product-features-fieldset">
                                        <!-- header -->
                                        <div class="product-features-header">
                                            <h2 [innerHTML]="product.productName" class="product-features-name"></h2>
                                            <h5 class="product-features-category">{{product.applicationCode}}</h5>
                                        </div>
                                        <!-- /header -->

                                        <!-- body -->
                                        <div class="product-features-body">
                                            <!-- alias -->
                                            <div class="product-features-container product-features-alias">
                                                <div class="product-features-info">
                                                    <h4 class="product-features-subhead">Account Nickname</h4>
                                                    <p>You can give this account a unique name to use throughout the application process.</p>
                                                </div>
                                                <div class="product-features-content">
                                                    <input type="text" [(ngModel)]="product.productAlias" [name]="product.id + '_' + alias" class="form-input product-features-alias-input">
                                                </div>
                                            </div>
                                            <!-- /alias -->

                                            <!-- options -->
                                            <div class="product-features-container product-features-options" *ngIf="product.features.length">
                                                <div class="product-features-info">
                                                    <h4 class="product-features-subhead">Additional Features</h4>
                                                    <p>This product has optional features for you to choose from. Please select as many as you would like.</p>
                                                </div>
                                                <div class="product-features-content">
                                                    <div class="product-options">
                                                        <feature-item *ngFor="let feature of product.features" [name]="product.id + '_' + feature.featureId" [feature]="feature"
                                                            class="form-product-option"></feature-item>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /options -->

                                            <!-- product questions -->
                                            <product-questions [questionGroups]="product.questionGroups"></product-questions>
                                            <!-- /product questions -->
                                        </div>
                                        <!-- /body -->
                                    </fieldset>
                                </form>
                            </div>
                        </li>
                    </ul>

                    <!-- comments -->
                    <div class="form-product-features-comments">
                        <div class="form-items form-test">
                            <label class="label-lg">
                                Questions or Comments
                                <span class="more-info">If you have questions or comments regarding any of your selected products please feel free to include them here. A bank representative will be sure to address them as soon as possible.</span>
                            </label>
                            <textarea class="form-textarea" rows="4" [(ngModel)]="comments"></textarea>
                        </div>
                    </div>
                    <!-- /comments -->
                </div>

                <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

            </div>
            <!-- /xl container -->
        </div>

    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="isLoading || !canSubmitProductQuestions()" (click)="selectFeatures()" data-continue-page="features" class="next-button">Continue</button>
    </div>
    <!-- /"contine" button -->

</main>