<main class="view-home">
    <section class="login-promo-banner" *ngIf="routedPromoCode !== '' && routedPromoCode !== 'none'">
        <div class="login-promo-container">
            A promo code has been entered for this session.
        </div>
    </section>
    
    <div class="login-container">
        <!-- col 1 -->
        <section class="login-info" [class.hidden]="isPanelVisible">
            <div class="login-info-container">
                <page-header [pageName]="pageName" class="page-header-logo-title page-header-home-title"></page-header>

                <!-- old UI footer -->
                <page-footer *ngIf="!useNewUiForBank" [pageName]="pageName" class="page-footer-content"></page-footer>

                <!-- mobile only options -->
                <div class="login-mobile-options">
                    <button class="login-mobile-signin" (click)="isPanelVisible = !isPanelVisible;">Sign Up / Continue</button>
                </div>
            </div>
        </section>
       
        <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
        
        <!-- col 2 -->
        <section class="login-forms" [class.hidden]="!isPanelVisible">

            <div class="login-user-toggle-container">
                <ul class="login-user-toggle">
                    <li *ngIf="!showBusinessView">
                        <a [routerLink]="['/home', 'newUser']" routerLinkActive="tabActive">Start New Application</a>
                    </li>
                    <li *ngIf="showBusinessView">
                        <a [routerLink]="['/home', 'newBusiness']" routerLinkActive="tabActive">Start New Application</a>
                    </li>
                    <li>
                        <a [routerLink]="['/home', 'existingUser']" routerLinkActive="tabActive">Resume Application</a>
                    </li>
                    <li *ngIf="shouldShowDigitalSignOn">
                        <a [routerLink]="['/home', 'digitalSignIn']" routerLinkActive="tabActive">Use My Digital Banking Login</a>
                    </li>
                    <li *ngIf="!useNewUiForBank" class="close-login-forms">
                        <a (click)="isPanelVisible = !isPanelVisible;">&times;</a>
                    </li>
                </ul>
            </div>

            <!-- RESKIN: PAGETEXT-->
            <!-- show pagetext for "new user" and not for "resume user" -->
            <page-header
                *ngIf="useNewUiForBank && isNew"
                [pageName]="pageName"
                class="page-body-pagetext">
            </page-header>
            <!-- /RESKIN: PAGETEXT -->
            <router-outlet></router-outlet>
        </section>

        <!-- RESKIN: FOOTER -->
        <page-footer *ngIf="useNewUiForBank" [pageName]="pageName" class="page-footer-content"></page-footer>
        <!-- /RESKIN: FOOTER -->

    </div>
</main>