<div class="container">
    <header class="signout-header">
        <!-- xl container -->
        <div class="xl-container">
            <div class="signout-branding">
                <img *ngIf="institutionState?.institution.logo" src="data:image/jpeg;base64,{{ institutionState?.institution.logo }}" class="main-logo" />
            </div>
        </div>
        <!-- /xl container -->
        <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
    </header>

    <section class="signout-body">
        <!-- xl container -->
        <div class="xl-container">
            <div class="signout-content">
                <div *ngIf="useNewUiForBank" class="signout-image">
                    <svg xmlns="http://www.w3.org/2000/svg" width="123" height="103" class="art-signout"><path class="window" fill="#8B8B8B" d="M0 0v103h123V0H0zm120 3v12H3V3h117zM3 100V18h117v82H3zm104.878-68.951H13.415v-3h94.463v3zM71.293 46.902H13.415V36.585h57.878v10.317zm-54.878-3h51.878v-4.317H16.415v4.317zm20.732 11.537H13.415v-3h23.732v3zm34.146 15.854H13.415V60.977h57.878v10.316zm-54.878-3h51.878v-4.316H16.415v4.316zm8.537 18.853H13.415V75.609h11.537v11.537zm-8.537-3h5.537v-5.537h-5.537v5.537zm24.61-1.878H27.049v-3h13.976v3z"/><path class="color" fill="#1a1a1a" d="M9 10.5H6v-3h3v3zm6-3h-3v3h3v-3zm6 0h-3v3h3v-3zm94 0H25v3h90v-3zM106.658 70.73v-5.832c-.06-5.415-4.513-9.868-9.928-9.928-2.672-.005-5.212.988-7.129 2.863a9.985 9.985 0 00-3.017 7.081v5.815h-3.658v20.073h27.39V70.73h-3.658zm-17.073-5.798a6.993 6.993 0 012.115-4.954 6.99 6.99 0 014.919-2.008l.078.001c3.797.041 6.92 3.164 6.961 6.944v5.815H89.585v-5.798zm17.731 22.872h-21.39V73.73h21.39v14.074zm-10.694-3.706a3.333 3.333 0 01-3.329-3.33c0-1.835 1.493-3.328 3.329-3.328s3.329 1.493 3.329 3.328a3.333 3.333 0 01-3.329 3.33zm0-3.659a.328.328 0 10-.001.657.328.328 0 00.001-.657z"/></svg>
                </div>

                <h2 class="signout-title">Signed Out</h2>
                <div class="signout-text">
                    <p class="text-signedout">You have been successfully signed out. You can log in to continue your process at any time using your unique Confirmation Code.</p>
                    <p>Your Confirmation Code is:</p>
                    <h1 class="text-code">{{ confirmationCode }}</h1>
                    <p class="text-instructions"><small>Please store your Confirmation Code in a safe, secure location as you will need it to log back in to resume your process.</small></p>

                    <button (click)="returnToLogin()" class="signout-button-return">
                        Return to Welcome Screen
                    </button>
                </div>
            </div>
        </div>
        <!-- /xl container -->
    </section>
</div>