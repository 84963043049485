<main id="view-persona-consent">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="persona-consent-content">
        <div class="inner-main-content">
            <!-- xl container -->
            <div class="xl-container">

                <div class="page-header-content">
                    <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>
                    <div  class="fdic-logo"><img src="assets/svg/fdic-digital-sign.svg" alt="logo"/></div>
                    <!-- RESKIN: NAV -->
                    <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                        <wizard-progress></wizard-progress>
                    </div>
                    <!-- /RESKIN: NAV -->

                </div>

                <csi-loading [isLoading]="isLoading"></csi-loading>

                <!-- RESKIN: PAGETEXT -->
                <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
                <!-- /RESKIN: PAGETEXT -->

          

                <div *ngIf="!isLoading" class="page-body-content">
                   <p style="text-align: center; max-width: 750px; margin: 0 auto;"> You acknowledge that you have reviewed <a href="https://www.csiweb.com/privacy-policy/" target="_blank">CSI's Privacy Policy</a>, 
                    have been advised, understand and consent to CSI, 
                    its subsidiaries and affiliates, vendors (including <a href="https://withpersona.com/legal/privacy-policy" target="_blank">Persona</a>), 
                    and/or their service providers collecting, storing, and 
                    using your biometric information through the use of CSI's or its vendors software and 
                    services (including Persona) for the purpose of identity verification and identifying fraud which may include a scan
                     of your face, including your face geometry. Your biometric information will be stored while you maintain an account 
                     with CSI and for no more than 1 year after your last interaction with CSI. You may revoke this consent at any time 
                     by notifying CSI in writing.</p>
                </div>

                <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

            </div>
            <!-- /xl container -->
        </div>

    </section>
   
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="isLoading" (click)="navigateNext()" data-continue-page="persona" class="next-button">I consent</button>
    </div>
    <!-- /"contine" button -->

</main>